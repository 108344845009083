import { Image } from '@nextui-org/react'
import React from 'react'
import { Button } from "@nextui-org/react";
import * as ConfigUtil from "util/ConfigUtil.js";
import * as UrlUtil from "util/UrlUtil.js";

// Condense further if desired.
export default function ButtonOpenApp({applicationName}) {
    let retElem;
    if (applicationName === "appkarma") {
        retElem = <Button
                        className="w-full mt-2 mb-4 text-[#FFFFFF]"
                        style={{ backgroundColor: "#32C072", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
                        onClick={() => {
                        let linkUrl = ConfigUtil.getBranchAppLink(applicationName);
                            UrlUtil.openUrl3(linkUrl);
                        }}
                    >
                        Open AppKarma 
                    </Button>
    } else if (applicationName === "cashkarma") {
        retElem = <Button
                        className="w-full mt-2 mb-4 text-[#FFFFFF]"
                        style={{ backgroundColor: "#FF7400", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
                        onClick={() => {
                        let linkUrl = ConfigUtil.getBranchAppLink(applicationName);
                            UrlUtil.openUrl3(linkUrl);
                        }}
                    >
                        Open CashKarma
                    </Button>
    } else {
        retElem = <Button
                    className="w-full mt-2 mb-4 text-[#FFFFFF]"
                    style={{ backgroundColor: "#32C072", fontFamily: 'FiraSans-Bold', borderRadius: '50px' }}
                    onClick={() => {
                    let linkUrl = ConfigUtil.getBranchAppLink(applicationName);
                        UrlUtil.openUrl3(linkUrl);
                    }}
            >
            Open PLACEHOLDER 
        </Button>
    }
  return retElem;
}
