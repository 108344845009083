import * as MyConfigFile from "../my_config_file_generated.js";

export const getBaseURL = function() {
    let config = MyConfigFile.getConfig();
    var baseUrl = config["base_url"];
    return baseUrl;
}

export const isLIVE = function() {
    let config = MyConfigFile.getConfig();
    let configType = config["config_type"];
    let retVAlue;
    if (configType === "LIVE") {
        retVAlue = true;
    } else {
        retVAlue = false;
    }
    return retVAlue;
}

export const getDeploymentType = function() {
    let config = MyConfigFile.getConfig();
    let configType = config["config_type"];
    return configType;
}

export const getBrowserRefreshWindowSec = function() {
    let config = MyConfigFile.getConfig();
    let browserRefreshWindowSec = config["browser_refresh_window_sec"];
    return browserRefreshWindowSec;
}

export const getClientVersion = function() {
    let config = MyConfigFile.getConfig();
    let clientVersion = config["app_version"];
    return clientVersion;
}

export const getBranchAppLink = function(applicationName) {
    let config = MyConfigFile.getConfig();
    let clientVersion;
    
    if (applicationName === "appkarma") {
        clientVersion = config["open_app_link_ak"];
    } else if (applicationName === "cashkarma") {
        clientVersion = config["open_app_link_ck"];
    } else {
        clientVersion = config["open_app_link"];
    }
    
    return clientVersion;
}