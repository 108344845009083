import { Button } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import AppKarmaLogo from "../../../component/Icons/AppKarma/AppKarmaLogo.js";
import SuccessConfirmationIcon from "../../../component/Icons/AppKarma/SuccessConfirmationIcon.js";

import * as UnsubscribeRequest from "../../../http_request/UnsubscribeRequest.js";
import * as UrlUtil from "../../../util/UrlUtil.js";
import ButtonOpenApp from "component/PageComponents/ButtonOpenApp.js";

let APPLICATION_NAME = "appkarma";

export default function EmailUnsubscribedConfirmation() {

    //const [loading, setLoading] = useState(true);
    const [isSuccessResponse, setIsSuccessResponse] = useState(false);
    const [responseMsg, setResponseMsg] = useState(null);

    useEffect(() => {
      let locObj = UrlUtil.getLocationUrlData();
      let hasVal = locObj["hash"];
      UnsubscribeRequest.unsubscribeAccount(hasVal,
        (errorInfo) => {
          if (errorInfo) {
            //setLoading(false);
            setIsSuccessResponse(false);
            if (errorInfo["errorMsg"]) {
              setResponseMsg(errorInfo["errorMsg"]);
            } else {
              let fallbackMsg = "Something went wrong.";
              setResponseMsg(fallbackMsg);
            }
            //ToastUtil.showToastReqError(errorInfo)
          } else {
            //setLoading(false);
            setIsSuccessResponse(true);
            setResponseMsg("You have been unsubscribed successfully");
          }
      });
    }, []);
  return (
    <div
      className="h-screen flex flex-col items-center justify-center overflow-scroll"
      style={{ backgroundColor: "#EFFBF4", fontFamily: "Fira Sans, sans-serif" }}>
      <div className="mb-4">
        <AppKarmaLogo />
      </div>
      <div
        className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
        style={{ backgroundColor: "#FFFFFF", border: '2px solid #32C072', borderRadius: '50px',
                display: (responseMsg != null && isSuccessResponse) ? "block": "none"
        }}>
        <div className="rounded-md p-2 w-full">
          <div className="flex justify-center">
            <div>
              <SuccessConfirmationIcon />
            </div>
          </div>
          <div className="text-center p-4 text-white">
            <div className="text-[#212121] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Email Unsubscribed</div>
            <div className="text-[#424242] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
              {responseMsg}
            </div>
          </div>
          <div className="px-3">
            <ButtonOpenApp applicationName={APPLICATION_NAME} />
          </div>
        </div>
      </div>
      {ErrorView(responseMsg, isSuccessResponse)}
    </div>
  );
}

function ErrorView(responseMsg, isSuccessResponse) {
  return <div
          className="lg:w-1/3 md:w-1/2 w-96 flex items-center justify-center p-4 rounded-lg"
          style={{ backgroundColor: "#EFFBF4" ,
                  display: (responseMsg != null && isSuccessResponse === false) ? "block": "none"
          }}>
  <div className="rounded-md p-2 w-full">
    <div className="text-center p-4 text-white">
      <div className="text-[#EF5350] text-2xl" style={{ fontFamily: 'FiraSans-ExtraBold'}}>Unsubscribe Failed!</div>
      <div className="text-[#424242] text-sm mt-4" style={{ fontFamily: 'Montserrat-Regular' }}>
        {responseMsg}
      </div>
    </div>
  </div>
</div>

}
