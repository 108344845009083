import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import router from "./router/index";
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from "react-responsive";

const root = ReactDOM.createRoot(document.getElementById("root"));

const MainContainer = function() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return <React.Fragment>
  <Toaster
        position={isMobile ? "top-center" : "top-right"}
        reverseOrder={false}
      />
    <RouterProvider router={router} />
    </React.Fragment>
}

root.render(
  <React.StrictMode>
    <MainContainer />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
